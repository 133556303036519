.activity-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centered-col {
  margin-top: 3px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.middle-date-col {
  padding-left: 13px !important;
  padding-right: 0px !important;
}

.activity-img {
  height: 180px;
}

.activity-upload-img {
  height: 280px;
}

.activity-date {
  color: #afafaf;
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 18px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.activity-header {
  font-size: 290px;
  line-height: 26px;
  margin-bottom: 2px;
}

.activity-available-spaces {
  align-items: center;
  color: #777;
  display: flex;
  line-height: 24px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.registerBtnWrapper {
  right: 10px;
}

.registerBtn {
  border-radius: 60px !important;
  height: 30px !important;
  width: 30px !important;
  position: relative;
}

.registerBtnIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
}

.usersContainer {
  bottom: 10px;
  right: 3px;
}

.reserved-bg {
  position: absolute;
  top: -10px;
  right: 10px;
}

.cancelRegistrationBtn {
  position: absolute;
  top: -8px;
  left: -8px;
}

.reserved-stamp {
  color: #555;
  font-size: 1rem;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: 'Courier';
  -webkit-mask-image: url(grunge.png);
  -webkit-mask-size: 944px 604px;
  mix-blend-mode: var(--falcon-reserved-stamp-blend-mode);
  color: #0a9928;
  border: 0.5rem solid #0a9928;
  -webkit-mask-position: 13rem 6rem;
  border-radius: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0a9928;
}

.create-activity-day-header-label {
  margin: 8px 12px;
}

.create-activity-day-rows {
  flex-direction: row;
  display: flex;
  margin-top: 5px;
}

.create-activity-day-button {
  background-color: #fff;
  color: var(--falcon-primary) !important;
  width: 3.2rem;
  height: 3.2rem;
  margin: 2px 10px;
  text-decoration: none !important;
  border-radius: 50%;
  line-height: 3rem;
  padding: 0 !important;
  transition: all 0.2s ease-in-out;
  border-color: #636e80;
  border-width: 1.3px;
  border-style: solid;
  display: flex;
  cursor: pointer;
}

.create-activity-day-selected-button {
  background-color: var(--falcon-primary) !important;
  color: #fff !important;
  border-color: #fff !important;
  cursor: pointer;
}

.create-activity-select-all-button {
  margin-left: 12px !important;
  color: var(--falcon-primary) !important;
  background-color: transparent;
  margin: auto 0px;
  font-weight: 500;
  cursor: pointer;
}

.create-activity-day-button-span {
  margin: auto;
  font-size: 0.9rem;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.create-activity-selected-days-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 25px;
}

.create-activity-selected-days-row {
  display: flex;
  flex-direction: row;
  margin: 15px;
  background-color: #eef1f8;
  padding: 10px;
  border-radius: 15px;
  width: 80%;
}

.create-activity-selected-days-row-day-label-header {
  font-size: 1rem;
  font-weight: 400;
}
.create-activity-selected-days-row-day-label {
  margin: auto;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.create-activity-repeat-row {
  width: 80%;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

.create-activity-repeat-column {
  padding-right: 0px !important;
}

.create-activity-repeat-button {
  color: var(--falcon-primary) !important;
  background-color: transparent;
  margin: auto 0px;
  font-weight: 600;
  cursor: pointer;
  text-align: right;
  font-size: 14px;
}

.react-datepicker-wrapper .invalid-feedback {
  display: block;
}

@media only screen and (min-width: 1000px){
  .activity-date-filter{
    width: 480px;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .activity-card-header {
    display: block;
  }
  .activity-date-filter {
    margin-top: 8px !important;
  }
  .reserved-bg {
    position: relative;
    top: -3px;
    left: 0px;
  }

  .create-activity-day-rows {
    display: inline-flex;
    max-width: calc(100%);
    flex-wrap: wrap;
  }

  .create-activity-day-button {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin-bottom: 5px;
    border-color: #b9c1d0;
  }

  .create-activity-day-button-span {
    font-size: 13px;
  }

  .create-activity-repeat-row {
    width: 100%;
    display: grid !important;
  }

  .create-activity-repeat-button {
    float: left;
    border: 0px;
    border-bottom: 1.7px;
    border-style: solid;
    border-color: var(--falcon-primary) !important;
    margin-bottom: 8px;
  }

  .create-activity-selected-days-row {
    width: 100%;
    padding: 15px 0px 20px 0px;
    row-gap: 3px;
  }
}

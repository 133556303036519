.match-wrapper {
  transition: all 0.8s ease;
}
.swap-btn {
  width: 70px !important;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  background: #fff !important;
  border-radius: 100%;
  border: none;
  padding: 0 !important;
}
.swap-rotate {
  transform: translate(-50%, -50%) rotate(180deg);
}
.swap-btn img {
  width: 40px;
  height: 40px;
}

div.fadeMe {
  opacity:    0.5; 
  background: #000; 
  width:      100%;
  height:     100%; 
  z-index:    10;
  top:        0; 
  left:       0; 
  position:   fixed; 
}
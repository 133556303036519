.action-holder-ibjjf {
    background: transparent;
    display: flex;
    flex-direction: column;
    margin: 5px 0;

    @include media-breakpoint-up(md) {
        margin: 10px 0;
    }

    &.final {
        ul {
            &:first-child {
                li {
                    min-width: 100%;
                    
                    button {
                        min-width: 100%;
                    }
                }
            }
        }
    }

    &.alt {
        background: transparent;
        // max-width: 25vw;

        .inner-holder {
            display: flex;
            flex-direction: column;

            .title {
                display: block;
                background: #2a2a2a;
                padding: 10px;
                text-align: center;
                font-size: 1vw;
                text-transform: uppercase;
                font-weight: bold;
                line-height: 1.2;

                @media screen and (max-width: 960px) {
                    padding: 8px;
                    line-height: 1;
                }
            }

            ul {
                flex: 1;
                li {
                    min-width: 50%;
                    button {
                        text-align: center;
                        justify-content: center;
                        min-width: 100%;
                        font-size: 1vw;

                        @media screen and (max-width: 960px) {
                            font-size: 1.25vw;
                        }
                
                        @media screen and (max-width: 767px) and (orientation: portrait) {
                            font-size: 1.25vw;
                        }
                
                        @media screen and (max-width: 900px) and (orientation: landscape) {
                            font-size: 1.5vw;
                        }
                    }
                }
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
            display: inline-flex;
            flex: 1;
            button {
                background: transparent;
                border-radius: 0;
                border-color: $black;
                min-width: 4vw;
                box-shadow: none;
                padding: 7px;
                text-transform: uppercase;
                font-size: 1vw;
                line-height: 1.5;
                
                @media only screen and (max-width: 477px) {
                   
                    width: 35px;
                }
                @media screen and (max-width: 960px) {
                    font-size: 1.25vw;
                    line-height: 1;
                }
        
                @media screen and (max-width: 767px) and (orientation: portrait) {
                    font-size: 1.25vw;
                }
        
                @media screen and (max-width: 900px) and (orientation: landscape) {
                    font-size: 1.5vw;
                }

                &:hover {
                    opacity: 0.8;
                }

                &.btn-advantage {
                    color: #78a575;
                }

                &.btn-penalty {
                    color: #000000;
                }

                &.btn-grey {
                    background: #3d3d3d;
                    color: $white;
                }         
            }

            .btn-primary:disabled{
                background-color: transparent;
                border-color: $black;
            }
            
        }
    }

   
}


@media only screen and (max-width: 477px) {
    .score-card-poits
    {
        margin: auto;
        margin-top: 4px !important;
        align-items: start !important;
        justify-content: center !important;
    }

    .player-controll{
        justify-content: center !important;
    }
}
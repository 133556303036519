.login-block {
  max-width: 700px;
  margin: 0 auto;
}

.login-nav-tabs {
  border: none;
  background: rgba(231, 234, 255, 0.15);
  border-radius: 25px;
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  border-bottom: none !important;
}

.login .nav-item {
  flex: 1;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  height: 40px;
}

.login-tab-block {
  background: rgba(231, 234, 255, 0.15);
  padding: 30px;
  margin-top: 10px;
  border-radius: 10px;
  color: rgba(231, 234, 255, 0.9) !important;
}

.login-tab-block a {
  color: rgba(231, 234, 255, 0.9) !important;
  padding-bottom: 1px;
  font-size: var(--falcon-body-font-size) !important;
  border: 0px;
  border-bottom: 1px;
  border-style: solid;
  border-color: rgba(231, 234, 255, 0.9) !important;
  margin-left: 5px;
}

.login-tab-block a:hover {
  text-decoration: none;
  font-weight: 600;
}

.login-tab-block label[for='rememberMe'] {
  color: rgba(231, 234, 255, 0.9) !important;
  padding-bottom: 1px;
  font-size: var(--falcon-body-font-size) !important;
  margin-left: 0px !important;
}

.login-tab-block button[type='submit'] {
  margin-bottom: 10px;
  font-size: 18px;
  padding: 10px 0px !important;
  border-radius: 8px;
}

.login-nav-link {
  border: none !important;
  color: rgba(231, 234, 255, 0.9) !important;
  border-radius: 22px !important;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  position: relative;
  padding: 10px 15px;
  flex: 1;
  width: 100%;
  justify-content: center !important;
  display: flex;
}

.login-nav-link div {
  margin: auto;
}

.login-nav-link svg {
  margin-top: -5px;
}

.login-nav-link span {
  padding-left: 8px;
  font-size: 18px;
}

.login-nav-link.active {
  color: #171719 !important;
  background-color: #f4f6fb !important;
  border-bottom-color: #fff !important;
  border: 1px solid #ddd !important;
  transition: ease-in;
  transition-duration: 0.2s;
}

.login-nav-link:not(.active):hover {
  background: #171719 !important;
  background-color: rgba(231, 234, 255, 0.1) !important;
}

.login-header {
  color: #f4f6fb !important;
  font-size: 28px;
  margin-bottom: 15px;
}

.bg-dark .container .navbar-nav .nav-link {
  color: hsla(0, 0%, 100%, 0.55) !important;
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
  .login-block {
    top: 0vh;
    position: relative;
    margin: 0px 5px;
    width: 95% !important;
  }

  .publicMatDetail #loginNavMenu,
  .home #loginNavMenu,
  .publicEventsSection #loginNavMenu,
  .loginSignInSection #loginNavMenu {
    align-self: flex-start;
  }

  .publicMatDetail #languageBar,
  .publicEventsSection #languageBar,
  .loginSignInSection #languageBar {
    display: none;
  }
}

.registration-icon-border {
  border-color: black;
  border-width: 3px;
  border-style: solid;
  border-radius: 80px;
  padding: 8px;
  width: min-content;
}

.academy_public_profile_belt_container {
    width: 17px;
    margin-right: 0.83333333rem !important;
  }
  .academy_public_profile_instagram_btn{
    margin-top: 13px;
    margin-left: 10px;
  }

  .academy_public_profile_avatar_url {
    width: 140px !important;
  }

  .registrationMain {
    overflow-x: hidden;
  }

  .card-followers{
    box-shadow: none !important;
  }
.public-events-nav-tabs {
  border: none;
  background: rgba(231, 234, 255, 0.15);
  border-radius: 25px;
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  border-bottom: none !important;
}

.public-events-nav-tabs .nav-item {
  flex: 1;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  height: 40px;
}

.public-events-nav-link {
  border: none !important;
  color: rgba(231, 234, 255, 0.9) !important;
  border-radius: 22px !important;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  position: relative;
  padding: 10px 15px;
  flex: 1;
  width: 100%;
  justify-content: center !important;
  display: flex;
}

.public-events-nav-link.active {
  color: #171719 !important;
  background-color: #f4f6fb !important;
  border-bottom-color: #fff !important;
  border: 1px solid #ddd !important;
}

.public-events-nav-link:not(.active):hover {
  background: #171719 !important;
  background-color: rgba(231, 234, 255, 0.1) !important;
}

.public-events-form-label {
  color: #f4f6fb !important;
}

.events-title {
    text-align: left;
}
